var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', _vm._g(_vm._b({
    staticClass: "px-8 py-2 text-white transition-all duration-300 ease-in-out rounded-full outline-none cursor-pointer bg-primary shadow-primary-xl hover:shadow-primary-2xl focus:shadow-primary-2xl hover:bg-primary-dark focus:bg-primary-900 focus:outline-none hover:outline-none",
    attrs: {
      "type": "button",
      "disabled": this.disabled
    }
  }, 'button', _vm.$props, false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }