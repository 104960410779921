var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex text-center bg-transparent items-center justify-center ps-2  w-20 "
  }, [_vm.iconTitle ? _c('ModalTitle', {
    attrs: {
      "text": _vm.countryCode,
      "textSize": "text-sm",
      "textColor": "text-secondary",
      "dir": "ltr"
    }
  }) : _vm._e(), _vm._v(" "), _c('img', {
    staticClass: "px-2",
    attrs: {
      "src": require(("~/assets/images/model/" + (_vm.icon))),
      "alt": _vm.alt
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }