//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    },
    isPlannerAiPage() {
      return this.$route.fullPath == '/plannerAi'
    },
  },
  methods: {
    ...mapMutations({
      toggleLoginModal: 'login/toggleModal',
    }),
    showLogin() {
      this.toggleLoginModal()
    },
    async doSomething(path) {
      if (path == 'businessEvents') {
        await this.$store.commit(
          'businessEvents/SET_IS_EDIT_EVENT_REQUESTS',
          false
        )
        await this.$store.commit(
          'businessEvents/SET_CURRENT_EVENT_REQUESTS',
          null
        )
      }
      this.$router.push({
        path: this.localePath(`/${path}`),
      })
    },
  },
}
