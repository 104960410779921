//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import SidebarItem from './SidebarItem/SidebarItem'

const navLinks = [
  // {
  //   translate: 'dashboard.home',
  //   path: '/dashboard',
  //   image: {
  //     icon: 'home.svg',
  //     alt: 'home icon',
  //   },
  // },
  {
    translate: 'dashboard.bookings',
    path: '/dashboard/bookings',
    image: {
      icon: 'calander.svg',
      alt: 'calander icon',
    },
  },
  // {
  //   translate: 'dashboard.history',
  //   path: '/dashboard/history',
  //   image: {
  //     icon: 'history.svg',
  //     alt: 'history icon',
  //   },
  // },
  {
    translate: 'dashboard.edit',
    path: '/dashboard/edit',
    image: {
      icon: 'user.svg',
      alt: 'user icon',
    },
  },
]

export default {
  name: 'Sidebar',
  components: {
    SidebarItem,
  },
  data() {
    return {
      navLinks,
    }
  },
  computed: {
    openSidebar() {
      return this.$store.state.sidebar.isOpen
    },
  },
  methods: {
    ...mapMutations({
      toggleSidebar: 'sidebar/toggleSidebar',
    }),
    toggleTabs(tabNumber) {
      this.openTab = tabNumber
    },
  },
}
