var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isPlannerAiPage ? _c('footer', {
    staticClass: "bg-violet"
  }, [_c('div', {
    staticClass: "px-2 mx-auto text-white-700 max-w-7xl sm:px-6 lg:px-8"
  }, [_vm.isPlaceApp ? _c('div', {
    staticClass: "flex flex-wrap pb-7 pt-6 lg:flex-nowrap"
  }) : _vm._e(), _vm._v(" "), !_vm.isPlaceApp ? _c('div', {
    staticClass: "flex flex-wrap pb-12 pt-7 lg:flex-nowrap"
  }, [_c('div', {
    staticClass: "flex flex-wrap justify-between w-full flex-1/1 lg:flex-2/3 max-w-1/1 lg:max-w-2/3 sm:flex-nowrap"
  }, [_vm._m(0), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col flex-wrap flex-1 px-2 mt-5 sm:px-0 sm:flex-auto"
  }, [_c('span', {
    staticClass: "font-medium"
  }, [_vm._v(" روابط مهمة ")]), _vm._v(" "), _c('ul', {
    staticClass: "flex-1 mt-4 font-light ps-4 whitespace-nowrap"
  }, [_c('li', [_c('NuxtLink', {
    staticClass: "block mt-4",
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t('nav.home')) + "\n              ")])], 1), _vm._v(" "), _c('li', [_c('a', {
    staticClass: "block mt-4",
    attrs: {
      "href": _vm.localePath('aboutUs')
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t('nav.about')) + "\n              ")])]), _vm._v(" "), _c('li', [_c('a', {
    staticClass: "block mt-4",
    attrs: {
      "href": "https://www.blog.qosoor.sa/",
      "target": "_blank"
    }
  }, [_vm._v("\n                " + _vm._s(_vm.$t('nav.blog')) + "\n              ")])])])]), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col flex-wrap px-3 mt-5 flex-1/2 sm:px-0 sm:flex-auto"
  }, [_c('span', {
    staticClass: "font-medium title flex-1/1"
  }, [_vm._v(" الوصول السريع ")]), _vm._v(" "), _c('div', {
    staticClass: "flex-1 mt-4 font-light ps-4 whitespace-nowrap"
  }, [!_vm.isLoggedIn ? _c('button', {
    staticClass: "block mt-4 font-light",
    on: {
      "click": _vm.showLogin
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('modal.signIn')) + "\n            ")]) : _vm._e(), _vm._v(" "), !_vm.isLoggedIn ? _c('button', {
    staticClass: "block mt-4 font-light",
    on: {
      "click": _vm.showLogin
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('dashboard.bookings')) + "\n            ")]) : _vm._e(), _vm._v(" "), _vm.isLoggedIn ? _c('NuxtLink', {
    staticClass: "block mt-4",
    attrs: {
      "to": _vm.localePath('/dashboard/bookings')
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('dashboard.bookings')) + "\n            ")]) : _vm._e(), _vm._v(" "), _c('button', {
    staticClass: "block mt-4",
    on: {
      "click": function ($event) {
        return _vm.doSomething('businessEvents');
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('businessEvents.businessEvents')) + "\n            ")]), _vm._v(" "), _c('button', {
    staticClass: "block mt-4",
    on: {
      "click": function ($event) {
        return _vm.doSomething('vendorRequests');
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('vendorRequests.joinUs')) + "\n            ")])], 1)])]), _vm._v(" "), _vm._m(1)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "flex flex-wrap justify-between pb-12 last lg:flex-nowrap"
  }, [_c('div', {
    staticClass: "flex flex-wrap items-center justify-center flex-1/1 lg:flex-auto lg:justify-start md:flex-nowrap"
  }, [!_vm.isPlaceApp ? _c('div', {
    staticClass: "flex items-center mb-5 text-sm font-medium md:mb-0"
  }, [_c('a', {
    attrs: {
      "href": "https://www.blog.qosoor.sa/about-us/",
      "target": "_blank"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('nav.about')) + "\n          ")]), _vm._v(" "), _c('i', {
    staticClass: "mx-3 text-xs fas fa-circle"
  }), _vm._v(" "), _c('a', {
    attrs: {
      "href": "https://www.blog.qosoor.sa/",
      "target": "_blank"
    }
  }, [_vm._v("\n            سياسة الاستخدام\n          ")]), _vm._v(" "), _c('i', {
    staticClass: "mx-3 text-xs fas fa-circle"
  }), _vm._v(" "), _c('a', {
    staticClass: "flex items-center me-3",
    attrs: {
      "href": ""
    }
  }, [_vm._v("\n            English\n            ")])]) : _vm._e(), _vm._v(" "), !_vm.isPlaceApp ? _c('div', {
    staticClass: "px-5 line none md:block"
  }, [_c('span', {
    staticClass: "block h-4"
  })]) : _vm._e(), _vm._v(" "), _vm._m(2)]), _vm._v(" "), _vm._m(3)])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex lg:items-center lg:m-auto lg:w-1/3 sm:flex-auto sm:block sm:mb-3"
  }, [_c('a', {
    staticClass: "flex md:w-1/2 w-2/3 md:pe-0 pe-20",
    attrs: {
      "href": ""
    }
  }, [_c('img', {
    attrs: {
      "src": require("images/NewLogo.svg"),
      "alt": ""
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "ms-5 sm:ms-0 lg:mt-10"
  }, [_c('a', {
    staticClass: "block mt-5 font-medium font-base",
    attrs: {
      "href": "https://api.whatsapp.com/send/?phone=966504849043",
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "icon me-3.5 text-primary"
  }, [_c('i', {
    staticClass: "fab fa-whatsapp"
  })]), _vm._v(" "), _c('span', {
    staticClass: "ml-2",
    attrs: {
      "dir": "ltr"
    }
  }, [_vm._v("+966504849043")]), _vm._v(" "), _c('br')]), _vm._v(" "), _c('a', {
    staticClass: "block mt-5 font-medium font-base",
    attrs: {
      "href": "mailto:info@eventful.sa"
    }
  }, [_c('span', {
    staticClass: "icon me-3.5 text-primary"
  }, [_c('i', {
    staticClass: "fas fa-envelope"
  })]), _vm._v("\n              info@eventful.sa\n            ")]), _vm._v(" "), _c('hr', {
    staticStyle: {
      "margin-left": "150px"
    }
  }), _vm._v(" "), _c('a', {
    staticClass: "block mt-5 font-medium font-base",
    attrs: {
      "href": "https://api.whatsapp.com/send/?phone=966504849043",
      "target": "_blank"
    }
  }, [_c('div', [_c('span', {
    staticClass: "ml-2",
    attrs: {
      "dir": "rtl"
    }
  }, [_vm._v("فعاليات الأعمال:")]), _vm._v(" "), _c('span', {
    staticClass: "ml-2",
    attrs: {
      "dir": "ltr"
    }
  }, [_vm._v("+966505457219")])]), _vm._v(" "), _c('div', [_c('span', {
    staticClass: "ml-2",
    attrs: {
      "dir": "rtl"
    }
  }, [_vm._v("للاتصال:")]), _vm._v(" "), _c('span', {
    staticClass: "ml-2",
    attrs: {
      "dir": "ltr"
    }
  }, [_vm._v("0115107066")])]), _vm._v(" "), _c('br')])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex flex-wrap flex-1 max-w-full mt-10 lg:flex-1/3 lg:max-w-1/3"
  }, [_c('div', {
    staticClass: "flex mx-auto text-center"
  }, [_c('a', {
    staticClass: "inline-block",
    attrs: {
      "target": "_blank",
      "href": "https://maroof.sa/87856"
    }
  }, [_c('img', {
    attrs: {
      "src": require("images/Home/imgFooter.svg"),
      "alt": ""
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center m-auto"
  }, [_c('a', {
    staticClass: "flex bg-blue-500 items-center py-2.5 px-6 whitespace-nowrap hover:bg-blue-800 hover:text-white transition-all rounded-full bg-gray-200 me-9",
    staticStyle: {
      "background": "#1289ff"
    },
    attrs: {
      "target": "_blank",
      "href": "https://play.google.com/store/apps/details?id=app.qosoor.com&hl=ar&gl=US"
    }
  }, [_c('p', {
    staticClass: "mb-1 leading-none text-start"
  }, [_c('small', {
    staticClass: "text-xs leading-none"
  }, [_vm._v(" Download on the ")]), _vm._v(" "), _c('span', {
    staticClass: "block text-xs leading-none"
  }, [_vm._v(" Google Play ")])]), _vm._v(" "), _c('span', {
    staticClass: "text-lg ms-4"
  }, [_c('i', {
    staticClass: "fab fa-google-play"
  })])]), _vm._v(" "), _c('a', {
    staticClass: "flex bg-blue-500 items-center py-2.5 px-6 whitespace-nowrap hover:bg-blue-800 hover:text-white transition-all rounded-full bg-gray-200",
    staticStyle: {
      "background": "#1289ff"
    },
    attrs: {
      "target": "_blank",
      "href": "https://apps.apple.com/us/app/%D9%82%D8%B5%D9%88%D8%B1-qosoor-book-venues/id1357975527"
    }
  }, [_c('p', {
    staticClass: "mb-1 leading-none text-start"
  }, [_c('small', {
    staticClass: "text-xs leading-none"
  }, [_vm._v(" Download on the ")]), _vm._v(" "), _c('span', {
    staticClass: "block text-xs leading-none"
  }, [_vm._v(" App Store ")])]), _vm._v(" "), _c('span', {
    staticClass: "text-xl ms-4"
  }, [_c('i', {
    staticClass: "fab fa-apple"
  })])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center"
  }, [_c('a', {
    staticClass: "text-lg me-9",
    attrs: {
      "target": "_blank",
      "href": "https://instagram.com/qosoorsa"
    }
  }, [_c('i', {
    staticClass: "fab fa-instagram"
  })]), _vm._v(" "), _c('a', {
    staticClass: "text-lg me-9",
    attrs: {
      "target": "_blank",
      "href": "https://twitter.com/qosoorsa"
    }
  }, [_c('i', {
    staticClass: "fab fa-twitter"
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center justify-end mt-5 flex-1/1 lg:flex-auto lg:mt-0"
  }, [_c('img', {
    staticClass: "me-3",
    attrs: {
      "src": require("images/Home/payments.svg"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }