//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PrimaryButton',
  props: {
    classButton: {}, listeners:{}, attrs:{},
    disabled : {
      default: false
    }
  }
}
