var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inline-block"
  }, [_c('button', {
    staticClass: "w-full\n  focus:outline-none text-white text-xl py-2 px-8 rounded-md bg-primary shadow-custom rounded-full",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.buttonTitle) + "\n  ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }