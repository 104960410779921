var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showActivateModal ? _c('ModalContainer', {
    on: {
      "click-close": _vm.toggleActivateModal
    },
    scopedSlots: _vm._u([{
      key: "avatar",
      fn: function () {
        return [_c('ModalAvatar', {
          attrs: {
            "icon": _vm.avatarIcon
          }
        })];
      },
      proxy: true
    }, {
      key: "header",
      fn: function () {
        return [_c('ModalTitle', {
          attrs: {
            "text": _vm.activateAccount,
            "textSize": "text-2xl",
            "textColor": "text-SecondayTitle"
          }
        })];
      },
      proxy: true
    }, {
      key: "lead",
      fn: function () {
        return [_c('div', {
          staticClass: "flex flex-col"
        }, [_c('ModalTitle', {
          staticClass: "mx-1",
          attrs: {
            "text": _vm.activateGuide,
            "textColor": "text-grey-model"
          }
        }), _vm._v(" "), _c('ModalTitle', {
          staticClass: "mx-1",
          attrs: {
            "text": _vm.registaredPhone,
            "textColor": "text-grey-model"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "main",
      fn: function () {
        return [_c('div', {
          staticClass: "w-full flex justify-center"
        }, [_c('client-only', {
          attrs: {
            "placeholder": ""
          }
        }, [_c('CodeInput', {
          staticClass: "w-full flex justify-center",
          attrs: {
            "loading": false,
            "type": "number",
            "fieldWidth": 56,
            "fieldHeight": 56,
            "fields": _vm.numberOfFields
          },
          on: {
            "change": _vm.onChange,
            "complete": _vm.onComplete
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('ModalLink', {
          staticClass: "mb-2 lg:mb-8",
          attrs: {
            "text": _vm.receivedCode
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.showRetrieveModal.apply(null, arguments);
            }
          }
        }), _vm._v(" "), _c('AppButton', {
          staticClass: "w-4/5 py-2",
          attrs: {
            "buttonTitle": _vm.check
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.toggleActivateModal.apply(null, arguments);
            }
          }
        })];
      },
      proxy: true
    }], null, false, 2605325465)
  }, [_vm._v(" "), _vm._v(" "), _vm._v(" "), _vm._v(" "), _vm._v(" "), _vm.showActivateModal ? _c('div', {
    staticClass: "opacity-25 fixed inset-0 z-40 bg-black"
  }) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }