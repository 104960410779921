//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      breadCrumbs: null,
    }
  },
  computed: {
    showBreadCrumbs() {
      return this.$store.state.showBreadCrumbs
    },
  },
  watch: {
    async $route() {
      this.renderBreadCrumbs()
      await this.$store.commit('SET_SHOW_BREAD_CRUMBS', false)
    },
    '$store.state.BreadCrumbsIdToName'() {
      this.renderBreadCrumbs()
    },
  },
  mounted() {
    this.renderBreadCrumbs()
  },
  methods: {
    renderBreadCrumbs() {
      const fullPath = this.$router.currentRoute.path
      const paths = [{ name: 'Home', path: '/' }]
      const idToName = this.$store.state.BreadCrumbsIdToName
      if (fullPath !== '/') {
        const pathArray = fullPath.split('/')
        pathArray.shift()
        for (let i = 1, len = pathArray.length; i <= len; i++) {
          const pathString = pathArray.slice(0, i).join('/')
          const path = { name: pathArray[i - 1], path: `/${pathString}` }
          if (idToName.length !== 0) {
            const find = idToName.find(
              (idName) => parseInt(idName.id) === parseInt(path.name)
            )
            if (find !== undefined) {
              path.name = find.name
            }
          }
          paths.push(path)
        }
      }
      this.breadCrumbs = paths
    },
    redirect(path) {
      this.$router.push({
        path: this.localePath(path.path),
      })
    },
  },
}
