var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "uppercase cursor-pointer leading-loose ms-2 text-md",
    class: [_vm.textColor, _vm.textSize],
    attrs: {
      "href": _vm.link
    }
  }, [_vm._v("\n  " + _vm._s(_vm.text) + "\n")]);
}
var staticRenderFns = []

export { render, staticRenderFns }