//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalContainer from '../ModalContainer/ModalContainer'
import AppButton from '../../AppButton/AppButton'
import ModalAvatar from '../ModalAvatar/ModalAvatar'
import ModalTitle from '../ModalTitle/ModalTitle'
import ModalLink from '../ModalLink/ModalLink'
import FieldInput from '../FieldInput/FieldInput'
import FieldIcon from '../FieldIcon/FieldIcon'
import { mapMutations } from 'vuex'

export default {
  components: {
    ModalContainer,
    AppButton,
    ModalAvatar,
    ModalTitle,
    ModalLink,
    FieldInput,
    FieldIcon,
  },
  name: 'reset-password-modal',
  data() {
    return {
      resetPassword: 'إعادة تعيين كلمة المرور',
      resetGuide: 'أدخل كود إعادة التعيين  كلمة المرور الجديدة',
      check: 'تحقق',
      receivedCode: 'لم يصل الكود؟',

      codePlaceholder: 'أدخل الرمز المرسل',
      newPasswordPlaceholder: 'كلمة المرور الجديدة',
      checkPasswordPlaceholder: 'تأكيد كلمة المرور',

      codeInput: '',
      newPasswordInput: '',
      checkPasswordInput: '',

      avatarIcon: 'spyware',
      eyeIcon: 'eye.svg',

      passwordFieldType: 'password',
    }
  },
  methods: {
    ...mapMutations({
      toggleResetModal: 'resetModal/toggleModal',
      toggleRetrieveModal: 'retrieveModal/toggleModal',
    }),
    showRetrieveModal() {
      this.toggleResetModal()
      this.toggleRetrieveModal()
    },
    updateCodeInput(value) {
      this.codeInput = value
    },
    updatePassInput(value) {
      this.newPasswordInput = value
    },
    updateCheckPassInput(value) {
      this.checkPasswordInput = value
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
  },
  computed: {
    showResetModal: function () {
      return this.$store.state.resetModal.isOpen
    },
  },
}
