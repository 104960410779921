//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalContainer from '../ModalContainer/ModalContainer'
import PrimaryButton from '../../AppButton/PrimaryButton'
import ModalAvatar from '../ModalAvatar/ModalAvatar'
import ModalTitle from '../ModalTitle/ModalTitle'
import ModalLink from '../ModalLink/ModalLink'
import FieldInput from '../FieldInput/FieldInput'
import FieldIcon from '../FieldIcon/FieldIcon'
import { mapMutations } from 'vuex'
import { displayError } from '~/plugins/helpers'

export default {
  components: {
    ModalContainer,
    PrimaryButton,
    ModalAvatar,
    ModalTitle,
    ModalLink,
    FieldInput,
    FieldIcon,
  },
  name: 'login-model',
  data() {
    return {
      signIn: 'تسجيل الدخول',
      promotSignup: 'ليس لديك حساب لدينا؟',
      signup: 'الاشتراك',
      forgotPassword: 'نسيت كلمة المرور؟',
      phonePlaceholder: '5x-xxx-xxxx',
      passwordPlaceholder: 'كلمة المرور',
      phoneNumberInput: '',
      passwordInput: '',
      avatarIcon: 'person_avatar',
      flagIcon: 'flag.svg',
      flagIconTitle: '+966',
      eyeIcon: 'eye.svg',

      passwordFieldType: 'password',
      phoneNumber: ""
    }
  },
  methods: {
    ...mapMutations({
      toggleLogModal: 'login/toggleModal',
      toggleOneStepLoginModal: 'login/toggleOneStepLoginModal',
      toggleRegModal: 'regModal/toggleModal',
      toggleRetrieveModal: 'retrieveModal/toggleModal',
    }),
    loginWithOneStep() {
      this.toggleLogModal()
      this.toggleOneStepLoginModal()
    },
    showRegModal() {
      this.toggleLogModal()
      this.toggleRegModal()
    },
    showRetrieveModal() {
      this.toggleLogModal()
      this.toggleRetrieveModal()
    },
    updatePhoneInput(value) {
      this.phoneNumberInput = value
    },
    updatePassInput(value) {
      this.passwordInput = value
    },
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    async submit(){
      //toggleLogModal
      try {

        await this.$store.dispatch('login/doLogin', {
            phone_number: `966${this.phoneNumberInput.replaceAll('-','')}`,
            password:this.passwordInput
        })
          // .catch(e=> {
          //
          //   console.log(e, JSON.stringify(e))
          // });
      }catch(e){
        displayError(e, this);

      }

      // await console.log(this.$store.state.login.ip);
    }
  },
  computed: {
    showLogModal: function () {
      return this.$store.state.login.isOpen
    }
  },
}
