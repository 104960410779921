var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showRegModal ? _c('ModalContainer', {
    on: {
      "click-close": _vm.toggleRegModal
    },
    scopedSlots: _vm._u([{
      key: "avatar",
      fn: function () {
        return [_c('ModalAvatar', {
          attrs: {
            "icon": _vm.avatarIcon
          }
        })];
      },
      proxy: true
    }, {
      key: "header",
      fn: function () {
        return [_c('ModalTitle', {
          attrs: {
            "text": _vm.signUp,
            "textSize": "text-2xl",
            "textColor": "text-SecondayTitle"
          }
        })];
      },
      proxy: true
    }, {
      key: "lead",
      fn: function () {
        return [_c('ModalLink', {
          attrs: {
            "text": _vm.signIn,
            "textColor": "text-primary"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.showLoginModal.apply(null, arguments);
            }
          }
        }), _vm._v(" "), _c('ModalTitle', {
          staticClass: "mx-1",
          attrs: {
            "text": _vm.promotSignIn,
            "textColor": "text-grey-model"
          }
        })];
      },
      proxy: true
    }, {
      key: "main",
      fn: function () {
        return [_c('form', {
          staticClass: "w-full max-w-xl",
          on: {
            "submit": _vm.submit
          }
        }, [_c('div', {
          staticClass: "w-full"
        }, [_c('div', {
          staticClass: "flex flex-row  border-2 border-grey-secondary rounded-3xl px-3"
        }, [_c('FieldInput', {
          staticClass: "w-full",
          attrs: {
            "type": "text",
            "placeholder": _vm.namePlaceholder,
            "value": _vm.nameInput
          },
          on: {
            "handleInput": _vm.updateNameInput
          }
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "flex flex-row px-3 my-2 border-2 border-grey-secondary rounded-3xl"
        }, [_c('FieldInput', {
          staticClass: "flex-1 flex-end",
          attrs: {
            "type": "text",
            "placeholder": _vm.emailPlaceholder,
            "value": _vm.emailInput
          },
          on: {
            "handleInput": _vm.updateEmailInput
          }
        }), _vm._v(" "), _c('FieldIcon', {
          attrs: {
            "icon": _vm.emailIcon
          }
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "flex flex-row px-3 my-2 border-2 border-grey-secondary rounded-3xl"
        }, [_c('FieldInput', {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '##-###-####',
            expression: "'##-###-####'"
          }],
          staticClass: "flex-1 flext-end",
          attrs: {
            "type": "tel",
            "placeholder": _vm.phonePlaceholder,
            "maxlength": "11"
          },
          on: {
            "handleInput": _vm.updatePhoneInput
          },
          model: {
            value: _vm.phoneNumberInput,
            callback: function ($$v) {
              _vm.phoneNumberInput = $$v;
            },
            expression: "phoneNumberInput"
          }
        }), _vm._v(" "), _c('FieldIcon', {
          staticClass: "text-left",
          attrs: {
            "icon": _vm.flagIcon,
            "iconTitle": '+966'
          }
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "flex flex-row px-3 my-2 border-2 border-grey-secondary rounded-3xl"
        }, [_c('FieldInput', {
          staticClass: "flex-1 flext-end",
          attrs: {
            "type": _vm.passwordFieldType,
            "placeholder": _vm.passwordPlaceholder,
            "value": _vm.passwordInput
          },
          on: {
            "handleInput": _vm.updatePassInput
          }
        }), _vm._v(" "), _c('a', {
          staticClass: "flex items-center w-20 text-center bg-transparent",
          on: {
            "click": _vm.switchVisibility
          }
        }, [_c('FieldIcon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.eyeIcon
          }
        })], 1)], 1)])])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('AppButton', {
          staticClass: "w-full py-2",
          attrs: {
            "buttonTitle": _vm.registarNow
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.submit.apply(null, arguments);
            }
          }
        })];
      },
      proxy: true
    }], null, false, 3368418475)
  }, [_vm._v(" "), _vm._v(" "), _vm._v(" "), _vm._v(" "), _vm._v(" "), _vm.showRegModal ? _c('div', {
    staticClass: "opacity-25 fixed inset-0 z-40 bg-black"
  }) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }