var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NuxtLink', {
    staticClass: "m-3 flex items-center cursor-pointer border-r-2 border-transparent",
    attrs: {
      "to": _vm.to
    }
  }, [_c('img', {
    staticClass: "m-2",
    attrs: {
      "src": require(("~/assets/images/dashboard/" + (_vm.icon))),
      "alt": _vm.alt
    }
  }), _vm._v(" "), _c('h1', {
    staticClass: "text-sm text-secondary"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }