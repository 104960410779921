var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-42 mx-auto -mt-24"
  }, [_c('img', {
    attrs: {
      "src": require(("~/assets/images/model/" + (_vm.icon) + ".svg?inline")),
      "alt": "Avatar"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }