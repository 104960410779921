//
//
//
//
//
//
//
//
//
//

export default {
  name: 'modal-link',
  props: ['link', 'text', 'textColor', 'textSize'],
}
