//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import FieldInput from './FieldInput/FieldInput'
import FieldIcon from './FieldIcon/FieldIcon'
import ModalContainer from '~/components/Modals/ModalContainer/ModalContainer'
import PrimaryButton from '~/components/AppButton/PrimaryButton'
import ModalTitle from '~/components/Modals/ModalTitle/ModalTitle'
import ModalAvatar from '~/components/Modals/ModalAvatar/ModalAvatar'
import ModalLink from '~/components/Modals/ModalLink/ModalLink'

import { displayError, validatePhoneNumber } from '~/plugins/helpers'

// var document = window.document
export default {
  name: 'OneStepLoginActivateCodeModal',
  components: {
    ModalContainer,
    PrimaryButton,
    ModalAvatar,
    ModalTitle,
    ModalLink,
    FieldInput,
    FieldIcon
  },
  props:{
    phoneNumberInput: {
      type: String,
      default: '',
    },
    extraData: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {
      activateAccount: 'تسجيل الدخول',
      activateGuide: 'سجل الدخول بخطوة واحدة',

      avatarIcon: 'person_avatar',

      numberOfFields: 4,
      phoneNumber: '',
      activeCode: '',
      isCodeSent: false,
      message:'',
    }
  },
  methods: {
    ...mapMutations({
      toggleLogModal: 'login/toggleModal',
      toggleModal: 'login/toggleOneStepLoginModal',
    }),
    loginWithPassword() {
      this.toggleModal()
      this.toggleLogModal()
    },
    async doActivateAccount(){
      if(! this.activeCode) return
      try {
        const phoneNumber = `966${this.phoneNumber.replaceAll('-','')}`

        await this.$store.dispatch('login/activateCode', {
          phone_number: phoneNumber,
          code: this.activeCode,
          ...this.extraData,
        })
        this.toggleModal()
      } catch (e) {
        console.log('Error:',e)
        displayError(e, this)
        return;
      }
      this.isCodeSent = false
    },
    async sendCode() {
      if(! this.phoneNumber) return
      try {
        const phoneNumber = `966${this.phoneNumber.replaceAll('-','')}`
        validatePhoneNumber(phoneNumber)

        const response = await this.$store.dispatch('login/sendCode', {
          phone_number: phoneNumber,
          ...this.extraData,
        })

        if (this.notProductionEnv){
          this.message = response.message + `, OTP Code is: ${response.code}`
          this.activeCode = response.code
        }else{
          this.message = response.message
        }

        this.isCodeSent = true

      } catch (e) {
        this.message = e.message

        console.log('Error:',e)
        displayError(e, this)
      }
    },
    updatePhoneInput(value) {
      this.phoneNumber = value
    },
  },
  computed: {
    // showActivateModal: function () {
    //   return this.isShowModal
    // },
    showLogModal: function () {
      return this.$store.state.login.isOneStepModalOpen
    },
  },
  mounted() {
    this.phoneNumber = this.phoneNumberInput
  },
}
