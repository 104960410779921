//
//
//
//
//
//

export default {
  name: 'modal-title',
  props: ['text', 'textColor', 'textSize'],
}
