var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showBreadCrumbs ? _c('div', {
    staticClass: "flex flex-row-reverse flex-wrap justify-center pt-5 pb-3 text-lg"
  }, _vm._l(_vm.breadCrumbs, function (path, index) {
    return _c('button', {
      key: index,
      class: {
        'flex text-gray-700 cursor-default': true,
        'hover:text-blue-600 cursor-pointer': index !== _vm.breadCrumbs.length - 1
      },
      on: {
        "click": function ($event) {
          return _vm.redirect(path);
        }
      }
    }, [index !== _vm.breadCrumbs.length - 1 ? _c('svg', {
      staticClass: "mt-0.5 mx-2",
      attrs: {
        "xmlns": "http://www.w3.org/2000/svg",
        "height": "20",
        "width": "20"
      }
    }, [_c('path', {
      attrs: {
        "d": "m8 15-1.062-1.062L10.875 10 6.938 6.062 8 5l5 5Z"
      }
    })]) : _vm._e(), _vm._v(" "), _c('p', [_vm._v(_vm._s(path.name))]), _vm._v(" "), path.name === 'Home' ? _c('svg', {
      staticClass: "w-5 h-5 mt-0.5 mr-2",
      attrs: {
        "fill": "currentColor",
        "viewBox": "0 0 20 20",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "d": "M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
      }
    })]) : _vm._e()]);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }