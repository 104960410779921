//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalContainer from '../ModalContainer/ModalContainer'
import AppButton from '../../AppButton/AppButton'
import ModalAvatar from '../ModalAvatar/ModalAvatar'
import ModalTitle from '../ModalTitle/ModalTitle'
import ModalLink from '../ModalLink/ModalLink'
import FieldInput from '../FieldInput/FieldInput'
import FieldIcon from '../FieldIcon/FieldIcon'
import { mapMutations } from 'vuex'
import { displayError } from "~/plugins/helpers";

export default {
  components: {
    ModalContainer,
    AppButton,
    ModalAvatar,
    ModalTitle,
    ModalLink,
    FieldInput,
    FieldIcon,
  },
  name: 'registration-modal',
  data() {
    return {
      signUp: 'تسجيل مستخدم جديد',
      promotSignIn: 'هل تملك حساب بالفعل؟',
      signIn: 'تسجيل الدخول',
      registarNow: 'التسجيل الان',

      namePlaceholder: 'الاسم كاملا',
      phonePlaceholder: '5x-xxx-xxxx',
      emailPlaceholder: 'البريد الإلكتروني',
      passwordPlaceholder: 'كلمة المرور',

      nameInput: '',
      phoneNumberInput: '',
      emailInput: '',
      passwordInput: '',

      avatarIcon: 'person_avatar',
      flagIcon: 'flag.svg',
      flagIconTitle: '+966',
      emailIcon: 'email.svg',
      eyeIcon: 'eye.svg',

      passwordFieldType: 'password',
    }
  },
  methods: {
    ...mapMutations({
      toggleRegModal: 'regModal/toggleModal',
      toggleLogModal: 'login/toggleModal',
    }),
    showLoginModal() {
      this.toggleRegModal()
      this.toggleLogModal()
    },
    updateNameInput(value) {
      this.nameInput = value
    },
    updatePhoneInput(value) {
      this.phoneNumberInput = value
    },
    updateEmailInput(value) {
      this.emailInput = value
    },
    updatePassInput(value) {
      this.passwordInput = value
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    checkIsInputsValid(){
      return this.nameInput &&
      this.phoneNumberInput &&
      this.emailInput &&
      this.passwordInput;
    },
    async submit(){
      if(this.checkIsInputsValid()){
        try {
          await this.$store.dispatch('login/doRegister', {
            phone_number: `966${this.phoneNumberInput.replaceAll('-','')}`,
            password:this.passwordInput,
            full_name: this.nameInput,
            email: this.emailInput,
          })
        }catch(e){
          displayError(e, this);
        }
      }else{
        displayError(null, this, 'الرجاء اكمال بياناتك لتسجيل الحساب');
      }
    }
  },
  computed: {
    showRegModal: function () {
      return this.$store.state.regModal.isOpen
    },
  },
}
