//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalContainer from '../ModalContainer/ModalContainer'
import AppButton from '../../AppButton/AppButton'
import ModalAvatar from '../ModalAvatar/ModalAvatar'
import ModalTitle from '../ModalTitle/ModalTitle'
import ModalLink from '../ModalLink/ModalLink'
import FieldInput from '../FieldInput/FieldInput'
import FieldIcon from '../FieldIcon/FieldIcon'
import { mapMutations } from 'vuex'

export default {
  components: {
    ModalContainer,
    AppButton,
    ModalAvatar,
    ModalTitle,
    ModalLink,
    FieldInput,
    FieldIcon,
  },
  name: 'retrieve-password-modal',
  data() {
    return {
      forgotPassword: 'نسيت كلمة المرور؟',
      resetGuide: 'أدخل رقم الهاتف المسجل، لإرسال كود إعادة التعيين',
      sendCode: 'إرسال الكود',

      phonePlaceholder: '5x-xxx-xxxx',
      phoneNumberInput: '',

      avatarIcon: 'spyware',
      flagIconTitle: '+966',
      flagIcon: 'flag.svg',
    }
  },
  computed: {
    showRetrieveModal () {
      return this.$store.state.retrieveModal.isOpen
    },
  },
  methods: {
    ...mapMutations({
      toggleRetrieveModal: 'retrieveModal/toggleModal',
      toggleLoginModal: 'login/toggleModal',
    }),
    updatePhoneInput(value) {
      this.phoneNumberInput = value
    },
    goToLogin() {
      this.toggleRetrieveModal()
      this.toggleLoginModal()
    },
  }
}
