//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'field-input',
  props: {
    placeholder: String,
    type: String,
    dir: {
      type: String,
      default: 'rtl',
    },
  },
  data() {
    return {
      userInput: '',
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('handleInput', this.userInput)
    },
    handleSubmit() {
      this.$emit('handleSubmit');
    }
  },
  components: {},
}
