var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "dir": _vm.dir
    }
  }, [_vm.type === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInput,
      expression: "userInput"
    }],
    staticClass: "justify-self-end py-3 text-start relative\n      flex-1 flex-end\n      bg-transparent text-sm  ring-none outline-none shadow-none border-none\n      focus:ring-none focus:outline-none focus:border-none focus:shadow-none\n      px-4",
    attrs: {
      "placeholder": _vm.placeholder,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.userInput) ? _vm._i(_vm.userInput, null) > -1 : _vm.userInput
    },
    on: {
      "input": _vm.handleInput,
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleSubmit.apply(null, arguments);
      },
      "change": function ($event) {
        var $$a = _vm.userInput,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.userInput = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.userInput = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.userInput = $$c;
        }
      }
    }
  }) : _vm.type === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInput,
      expression: "userInput"
    }],
    staticClass: "justify-self-end py-3 text-start relative\n      flex-1 flex-end\n      bg-transparent text-sm  ring-none outline-none shadow-none border-none\n      focus:ring-none focus:outline-none focus:border-none focus:shadow-none\n      px-4",
    attrs: {
      "placeholder": _vm.placeholder,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.userInput, null)
    },
    on: {
      "input": _vm.handleInput,
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleSubmit.apply(null, arguments);
      },
      "change": function ($event) {
        _vm.userInput = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userInput,
      expression: "userInput"
    }],
    staticClass: "justify-self-end py-3 text-start relative\n      flex-1 flex-end\n      bg-transparent text-sm  ring-none outline-none shadow-none border-none\n      focus:ring-none focus:outline-none focus:border-none focus:shadow-none\n      px-4",
    attrs: {
      "placeholder": _vm.placeholder,
      "type": _vm.type
    },
    domProps: {
      "value": _vm.userInput
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.userInput = $event.target.value;
      }, _vm.handleInput],
      "keypress": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }