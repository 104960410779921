var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-4 relative"
  }, [_c('nav', {
    staticClass: "fixed top-0 left-0 right-0 z-40 bg-white backdrop-filter backdrop-blur-sm lg:z-40 rounded-b-xl"
  }, [_c('div', {
    staticClass: "px-2 mx-auto max-w-7xl sm:px-6 lg:px-8"
  }, [_c('div', {
    staticClass: "relative flex items-center justify-between h-16"
  }, [_c('div', {
    staticClass: "flex items-center flex-1 sm:items-stretch"
  }, [_c('div', {
    staticClass: "flex items-center flex-shrink-0"
  }, [_c('NuxtLink', {
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_c('img', {
    staticClass: "block w-auto h-8",
    attrs: {
      "src": require("images/NewLogo.svg"),
      "alt": "Eventful",
      "prefetch": ""
    }
  })])], 1), _vm._v(" "), _c('div', {
    staticClass: "inset-y-0 flex flex-row items-center hidden end-0 lg:flex",
    on: {
      "click": _vm.toggleSidebarLarge
    }
  }, [_c('button', {
    staticClass: "inline-flex items-center justify-center p-2 text-gray-400 rounded-md ms-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
    attrs: {
      "type": "button",
      "aria-expanded": "false"
    }
  }, [!_vm.isOpenMenu ? _c('svg', {
    staticClass: "block w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M4 6h16M4 12h16M4 18h16"
    }
  })]) : _c('svg', {
    staticClass: "block w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M6 18L18 6M6 6l12 12"
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center ms-4"
  }, [_c('NuxtLink', {
    staticClass: "bg-secondary-50 text-secondary-800 px-4 py-1 rounded-md",
    attrs: {
      "to": _vm.localePath('/')
    }
  }, [_c('i', {
    staticClass: "fas fa-home"
  }), _vm._v("\n                الرئيسية\n              ")])], 1)]), _vm._v(" "), _c('div', {
    staticClass: "inset-y-0 flex items-center block end-0 lg:hidden",
    on: {
      "click": _vm.toggleSidebar
    }
  }, [_c('button', {
    staticClass: "inline-flex items-center justify-center p-2 text-gray-400 rounded-md ms-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white",
    attrs: {
      "type": "button",
      "aria-expanded": "false"
    }
  }, [!_vm.isOpenMenu ? _c('svg', {
    staticClass: "block w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M4 6h16M4 12h16M4 18h16"
    }
  })]) : _c('svg', {
    staticClass: "block w-6 h-6",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0 0 24 24",
      "stroke": "currentColor",
      "aria-hidden": "true"
    }
  }, [_c('path', {
    attrs: {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "M6 18L18 6M6 6l12 12"
    }
  })])])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center ms-auto gap-x-1 lg:gap-x-4"
  }, [_c('div', {
    staticClass: "lg:flex ms-auto"
  }, [_c('div', {
    staticClass: "flex md:space-x-2 lg:space-x-8"
  }, _vm._l(_vm.availableLocales, function (locale) {
    return _c('NuxtLink', {
      key: locale.code,
      staticClass: "flex-no-wrap px-3 py-2 text-sm font-medium text-gray-900 flex-nowrap qosoor-link focus:qosoor-link-exact-active hover:qosoor-link-exact-active",
      attrs: {
        "to": _vm.switchLocalePath(locale.code)
      }
    }, [_c('span', {
      staticClass: "flex"
    }, [_vm._v("\n                    " + _vm._s(locale.name) + "\n                    "), _c('img', {
      staticClass: "ps-2",
      attrs: {
        "src": require('~/assets/images/languages/' + locale.icon),
        "alt": "Switch Language"
      }
    })])]);
  }), 1)]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center"
  }, [_c('button', [_c('img', {
    attrs: {
      "src": require("~/assets/images/dashboard/notification.svg"),
      "alt": "notifications"
    }
  })])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center mx-2 cursor-pointer lg:gap-x-4",
    on: {
      "click": _vm.toggleProfileMenu
    }
  }, [_vm.isOpenedProfileMenu ? _c('div', {
    staticClass: "w-11/12 sm:w-7/12 md:w-5/12 lg:w-3/12 absolute top-16 overflow-hidden fixed z-50 outline-none focus:outline-none bg-white text-secondary rounded-b-xl border-2 end-4"
  }, [_c('div', {
    staticClass: "relative w-full w-auto my-2"
  }, [_c('div', {
    staticClass: "flex flex-col divide-y text-sm"
  }, [_c('NuxtLink', {
    staticClass: "p-4 flex items-center justify-start",
    attrs: {
      "to": _vm.localePath('/dashboard/bookings')
    }
  }, [_c('img', {
    staticClass: "text-secondary w-4",
    attrs: {
      "src": require("~/assets/images/dashboard/calander.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('h6', {
    staticClass: "ms-4 text-sm"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t('dashboard.bookings')) + "\n                      ")])]), _vm._v(" "), _c('div', {
    staticClass: "p-4 flex items-center justify-start",
    attrs: {
      "to": _vm.localePath('/')
    },
    on: {
      "click": _vm.doLogout
    }
  }, [_c('img', {
    staticClass: "text-secondary w-4",
    attrs: {
      "src": require("~/assets/images/logout.svg"),
      "alt": ""
    }
  }), _vm._v(" "), _c('h6', {
    staticClass: "ms-4 text-sm"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t('nav.logout')) + "\n                      ")])])], 1)])]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "hidden lg:block"
  }, [_c('img', {
    attrs: {
      "src": require("~/assets/images/dashboard/down_arrow.svg"),
      "alt": "notifications"
    }
  })]), _vm._v(" "), _c('div', {
    staticClass: "hidden text-center lg:block"
  }, [_c('h1', {
    staticClass: "text-sm"
  }, [_vm._v(_vm._s(_vm.user.name))]), _vm._v(" "), _c('h2', {
    staticClass: "text-xs"
  }, [_vm._v(_vm._s(_vm.user.email))])]), _vm._v(" "), _c('div', {
    staticClass: "flex items-center w-8"
  }, [_c('button', [_c('img', {
    attrs: {
      "src": require("~/assets/images/dashboard/profile_user.svg"),
      "alt": "notifications"
    }
  })])])])])])])])]), _vm._v(" "), _c('div', {
    staticClass: "block h-12"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }