var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fixed h-full top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center w-full z-90 bg-secondary-500 "
  }, [!_vm.isPlaceApp ? _c('img', {
    staticClass: "w-32 h-auto",
    attrs: {
      "src": "/icon.gif"
    }
  }) : _c('div', {
    staticClass: "w-12 h-12 mb-4 ease-linear border-4 border-t-4 border-gray-200 rounded-full animate-spin loader border-t-primary "
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }