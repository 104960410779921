//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalContainer from '../ModalContainer/ModalContainer'
import AppButton from '../../AppButton/AppButton'
import ModalAvatar from '../ModalAvatar/ModalAvatar'
import ModalTitle from '../ModalTitle/ModalTitle'
import ModalLink from '../ModalLink/ModalLink'
import FieldInput from '../FieldInput/FieldInput'
import FieldIcon from '../FieldIcon/FieldIcon'
import { mapMutations } from 'vuex'

// var document = window.document
export default {
  components: {
    ModalContainer,
    AppButton,
    ModalAvatar,
    ModalTitle,
    ModalLink,
    FieldInput,
    FieldIcon,
  },
  name: 'retrieve-password-modal',
  data() {
    return {
      activateAccount: 'تفعيل الحساب',
      activateGuide: 'لطفا أدخل الكود المرسل إالى رقم الجوال',
      registaredPhone: '+966561234567',
      check: 'تحقق',
      receivedCode: 'لم يصل الكود؟',

      avatarIcon: 'person_avatar',

      numberOfFields: 4,
    }
  },
  methods: {
    ...mapMutations({
      toggleActivateModal: 'activateModal/toggleModal',
      toggleRetrieveModal: 'retrieveModal/toggleModal',
    }),
    showRetrieveModal() {
      this.toggleActivateModal()
      this.toggleRetrieveModal()
    },
    onChange(v) {
      console.log('onChange ', v)
    },
    onComplete(v) {
      console.log('onComplete ', v)
    },
  },
  computed: {
    showActivateModal: function () {
      return this.$store.state.activateModal.isOpen
    },
  },
}
