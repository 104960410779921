var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("default"), _vm._v(" "), _c('div', {
    staticClass: "overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex w-full max-h-screen overflow-x-hidden m-auto"
  }, [_c('div', {
    staticClass: "flex relative w-auto mx-5 lg:mx-auto w-full justify-center align-center"
  }, [_c('div', {
    staticClass: "border-0 rounded-lg shadow-xl relative flex flex-col bg-white outline-none focus:outline-none p-4 w-full max-w-3xl lg:max-w-md mt-20 max-w-4xl-custom max-h-screen",
    attrs: {
      "id": "modalContent"
    }
  }, [_vm._t("avatar"), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col items-center justify-center text-center w-full"
  }, [_vm.showCloseIcon ? _c('button', {
    staticClass: "absolute start-4 top-4 text-sm p-2 text-gray-500 focus:outline-none",
    on: {
      "click": function ($event) {
        return _vm.$emit('click-close');
      }
    }
  }, [_c('client-only', [_c('i', {
    staticClass: "p-2"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  })], 1)]), _vm._v(" "), _vm._t("closeText")], 2) : _vm._e(), _vm._v(" "), _vm._t("header"), _vm._v(" "), _c('div', {
    staticClass: "text-center flex flex-row-reverse p-2"
  }, [_vm._t("lead")], 2)], 2), _vm._v(" "), _c('div', {
    staticClass: "relative p-4 lg:px-4 lg:py-2 flex-auto my-1 w-full overflow-y-visible max-h-screen"
  }, [_c('div', {
    staticClass: "relative flex flex-col w-full flex-wrap justify-between items-center"
  }, [_vm._t("main")], 2)]), _vm._v(" "), _c('div', {
    staticClass: "flex flex-col items-center justify-center p-2 min-w-full"
  }, [_vm._t("footer")], 2)], 2)])])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }