var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-full z-50 lg:z-30 rounded-lg"
  }, [_vm.openSidebar ? _c('div', {
    staticClass: "\n      fixed\n      top-0\n      left-0\n      cursor-pointer\n      h-screen\n      w-screen\n      bg-black\n      opacity-50\n      z-40\n    ",
    on: {
      "click": _vm.toggleSidebar
    }
  }) : _vm._e(), _vm._v(" "), _c('transition', {
    staticClass: "slide"
  }, [_c('div', {
    staticClass: "\n        fixed\n        right-0\n        top-0\n        z-50\n        bg-white\n        shadow-md\n        h-full\n        w-3/5\n        md:w-2/5\n        lg:block\n        lg:relative\n        lg:w-full\n        lg:rounded-lg\n      ",
    class: _vm.openSidebar ? 'block' : 'hidden',
    attrs: {
      "id": "main-nav"
    }
  }, [_c('div', {
    staticClass: "py-4 w-full"
  }, _vm._l(_vm.navLinks, function (navLink, index) {
    return _c('SidebarItem', {
      key: 'nav' + index,
      attrs: {
        "to": _vm.localePath(navLink.path),
        "icon": navLink.image.icon,
        "alt": navLink.image.alt,
        "exact-active-class": "border-2 border-orange"
      }
    }, [_vm._v("\n          " + _vm._s(_vm.$t(navLink.translate)) + "\n        ")]);
  }), 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }