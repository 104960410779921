//
//
//
//
//
//
//

export default {
  name: 'modal-avatar',
  props: ['icon'],
}
