var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showResetModal ? _c('ModalContainer', {
    on: {
      "click-close": _vm.toggleResetModal
    },
    scopedSlots: _vm._u([{
      key: "avatar",
      fn: function () {
        return [_c('ModalAvatar', {
          attrs: {
            "icon": _vm.avatarIcon
          }
        })];
      },
      proxy: true
    }, {
      key: "header",
      fn: function () {
        return [_c('ModalTitle', {
          attrs: {
            "text": _vm.resetPassword,
            "textSize": "text-2xl",
            "textColor": "text-SecondayTitle"
          }
        })];
      },
      proxy: true
    }, {
      key: "lead",
      fn: function () {
        return [_c('ModalTitle', {
          staticClass: "mx-1",
          attrs: {
            "text": _vm.resetGuide,
            "textColor": "text-grey-model"
          }
        })];
      },
      proxy: true
    }, {
      key: "main",
      fn: function () {
        return [_c('div', {
          staticClass: "w-4/5"
        }, [_c('div', {
          staticClass: "flex flex-row border-2 border-grey-secondary rounded-3xl px-3 items-center"
        }, [_c('ModalLink', {
          staticClass: "w-full",
          attrs: {
            "text": _vm.receivedCode,
            "textColor": "text-qo-orange"
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.showRetrieveModal.apply(null, arguments);
            }
          }
        }), _vm._v(" "), _c('FieldInput', {
          staticClass: "w-3/5",
          attrs: {
            "type": "text",
            "placeholder": _vm.codePlaceholder,
            "value": _vm.codeInput
          },
          on: {
            "handleInput": _vm.updateCodeInput
          }
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "flex flex-row border-2 border-grey-secondary rounded-3xl px-3 my-1"
        }, [_c('a', {
          staticClass: "flex items-center",
          on: {
            "click": _vm.switchVisibility
          }
        }, [_c('FieldIcon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.eyeIcon
          }
        })], 1), _vm._v(" "), _c('FieldInput', {
          staticClass: "w-full",
          attrs: {
            "type": _vm.passwordFieldType,
            "placeholder": _vm.newPasswordPlaceholder,
            "value": _vm.newPasswordInput
          },
          on: {
            "handleInput": _vm.updatePassInput
          }
        })], 1), _vm._v(" "), _c('div', {
          staticClass: "flex flex-row border-2 border-grey-secondary rounded-3xl px-3 my-1"
        }, [_c('a', {
          staticClass: "flex items-center",
          on: {
            "click": _vm.switchVisibility
          }
        }, [_c('FieldIcon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.eyeIcon
          }
        })], 1), _vm._v(" "), _c('FieldInput', {
          staticClass: "w-full",
          attrs: {
            "type": _vm.passwordFieldType,
            "placeholder": _vm.checkPasswordPlaceholder,
            "value": _vm.checkPasswordInput
          },
          on: {
            "handleInput": _vm.updateCheckPassInput
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_c('AppButton', {
          staticClass: "w-4/5 py-2",
          attrs: {
            "buttonTitle": _vm.check
          },
          nativeOn: {
            "click": function ($event) {
              return _vm.toggleResetModal.apply(null, arguments);
            }
          }
        })];
      },
      proxy: true
    }], null, false, 4139937187)
  }, [_vm._v(" "), _vm._v(" "), _vm._v(" "), _vm._v(" "), _vm._v(" "), _vm.showResetModal ? _c('div', {
    staticClass: "opacity-25 fixed inset-0 z-40 bg-black"
  }) : _vm._e()]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }