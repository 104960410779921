//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalTitle from '../ModalTitle/ModalTitle'

export default {
  name: 'field-icon',
  props: ['icon', 'alt', 'icon-title'],
  components: {
    ModalTitle,
  },
  data() {
    return {
      countryCode: '+966',
    }
  },
}
