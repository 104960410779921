//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'sidebar-item',
  props: ['icon', 'alt', 'to'],
}
