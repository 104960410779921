//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'

export default {
  name: 'DashNavbar',
  data() {
    return {
      isOpenUserDetails: false,
      isOpenMenu: false,
      user: {
        name: 'يعرب المصطفى',
        email: 'yaroob@eventful.sa',
      },
    }
  },
  computed: {
    isOpenedMenu() {
      return this.$store.state.navbar.isOpenedMenu
    },
    isOpenedProfileMenu() {
      return this.$store.state.navbar.isOpenedProfileMenu
    },
    isLogin() {
      return this.$store.state.isLogin
    },
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale)
    },
  },
  methods: {
    ...mapMutations({
      toggleSidebar: 'sidebar/toggleSidebar',
      toggleSidebarLarge: 'sidebar/expandSidebar',
      toggleProfileMenu: 'navbar/toggleProfileMenu',
    }),
    toggleUserDetails() {
      this.isOpenUserDetails = !this.isOpenUserDetails
      // Ensures the event isn't registered too quickly and causes a double fire
      this.isOpenUserDetails &&
        setTimeout(() => {
          const app = document.getElementById('app')
          app.addEventListener('click', this.blurUserDetails, false)
        }, 250)
    },
    toggleMenu() {
      this.isOpenMenu = !this.isOpenMenu
    },
    blurUserDetails() {
      this.isOpenUserDetails = false
      const app = document.getElementById('app')
      app.removeEventListener('click', this.blurUserDetails, false)
    },
    doLogout() {
      this.$store.dispatch('login/doLogout')
      this.$router.push({ path: this.localePath('/') })
    },
  },
}
